import AppSubmitFormSection from "@/apps/list/form-sections/AppSubmitFormSection"
import LegacyAppIconAndTitleFormSection from "@/apps/list/form-sections/LegacyAppIconAndTitleFormSection"
import { useAppLevel } from "@/apps/util/appLevelContext"
import useAddAppFormStore from "@/apps/util/hooks/useAddAppFormStore"
import useEditAppFormStore from "@/apps/util/hooks/useEditAppFormStore"
import { AppDirectoryItemInput } from "@/apps/util/hooks/__generated__/useAddAppFormStoreMutation.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { GlobalID } from "@/relay/RelayTypes"
import Form from "@components/form/Form"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoFormControl, DiscoSection, DiscoText } from "@disco-ui"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import { generatePath, useHistory } from "react-router-dom"
import AppFormGroupsSection from "../../common/settings/AppFormGroupsSection"
import AppFormMembersSection from "../../common/settings/AppFormMembersSection"
import AppSettingsForm from "../../common/settings/AppSettingsForm"
import AppVisibilityForm from "../../common/settings/AppVisibilityForm"
import MembersAppFilterMultiSelect from "./MembersAppFilterMultiSelect"

interface Props {
  testid: string
  appId?: GlobalID
  onClose: () => void
}

function MembersSettingsForm({ testid, appId, onClose }: Props) {
  const activeOrganization = useActiveOrganization()!
  const { product } = useAppLevel()
  const { navFolderId, navSectionId } = useAppLevel()
  const memberLabel = useLabel(product ? "product_member" : "organization_member")
  const history = useHistory()
  const mode = appId ? "edit" : "add"
  const createForm = useAddAppFormStore({
    kind: "member_directory",
    customAppTitle: "Members",
    appDirectoryItems: [],
    navFolderId,
    navSectionId,
    visibility: "all",
    visibilityGroups: [],
    visibilityMembers: [],
    badge: {
      kind: "icon",
      icon: "people",
      color: "#ffffff00",
    },
  })
  const editForm = useEditAppFormStore(appId)

  const form = appId ? editForm : createForm

  return renderFormBody()

  function renderFormBody() {
    if (form.state.kind === "member_directory")
      return (
        <Form
          testid={"AppSetupModalForm"}
          errorInfo={null}
          knownErrorKeys={form.errors.map((e) => e.field)}
          onSubmit={handleSubmit}
          buttons={<AppSubmitFormSection form={form} mode={mode} onClose={onClose} />}
        >
          <LegacyAppIconAndTitleFormSection form={form} />

          {!product && (
            <>
              <AppVisibilityForm testid={testid} form={form} />

              {/** App visibility settings */}
              {form.state.visibility === "membership" && (
                <DiscoSection
                  groovyDepths={"insideCard"}
                  padding={1.5}
                  marginTop={-1}
                  marginLeft={0.25}
                  marginRight={0.25}
                  marginBottom={2.5}
                >
                  <AppFormGroupsSection
                    testid={`${testid}.access`}
                    memberGroupIds={toJS(form.state.visibilityGroups!)}
                    organizationId={activeOrganization.id}
                    onChange={(groupIds) =>
                      form.state.visibilityGroups!.replace(groupIds)
                    }
                    errorMessages={form.errorsByField.visibilityGroups}
                  />

                  <AppFormMembersSection
                    testid={`${testid}.access`}
                    membershipIds={toJS(form.state.visibilityMembers!)}
                    onChange={(memberships) =>
                      form.state.visibilityMembers!.replace(memberships.map((m) => m.id))
                    }
                    errorMessages={form.errorsByField.visibilityMembers}
                  />
                </DiscoSection>
              )}
            </>
          )}

          {form.state.kind === "member_directory" && (
            <DiscoFormControl
              label={<DiscoText variant={"body-sm"}>{"Select Groups"}</DiscoText>}
              marginBottom={2}
              tooltip={`Only ${memberLabel.plural} in the selected groups will appear in this directory.`}
            >
              <MembersAppFilterMultiSelect
                testid={testid}
                organizationId={activeOrganization.id}
                productId={product?.id}
                onChange={updateSelection}
                // Cannot filter on product members page by community groups so exclude when creating to keep consistent
                hideCommunityGroups={Boolean(product)}
                memberGroupIds={
                  form.state.appDirectoryItems?.length
                    ? form.state.appDirectoryItems?.map((i) => i.memberGroupId).slice()
                    : []
                }
              />
            </DiscoFormControl>
          )}
        </Form>
      )

    return (
      <AppSettingsForm
        testid={testid}
        form={editForm}
        mode={"edit"}
        hideIconAndTitle
        onSubmit={handleEditApp}
        onClose={onClose}
      />
    )
  }

  function updateSelection(groupIds: string[]) {
    if (!form.state.appDirectoryItems) return

    const result = groupIds.map((id) => ({
      memberGroupId: id,
    })) as AppDirectoryItemInput[]

    form.state.appDirectoryItems.replace(result)
  }

  async function handleSubmit() {
    if (mode === "add") {
      await handleAddApp()
    } else if (mode === "edit") {
      await handleEditApp()
    }
  }

  async function handleAddApp() {
    try {
      const { didSave, response } = await createForm.submit(
        {
          organizationId: activeOrganization.id,
          kind: "member_directory",
          customAppTitle: createForm.state.customAppTitle,
          appDirectoryItems: createForm.state.appDirectoryItems,
          badge: createForm.state.badge,
          productId: createForm.state.productId,
          ...(!product && {
            visibility: createForm.changedState.visibility,
            visibilityGroups: createForm.state.visibilityGroups,
            visibilityMembers: createForm.state.visibilityMembers,
          }),
        },
        {
          connections: [],
          variables: {
            isOrgTopLevel: !createForm.state.productId && !createForm.state.navSectionId,
          },
        }
      )

      if (!didSave || !response?.node) return

      displaySuccessToast({
        message: "Members app created!",
        testid: "CreateMembersAppForm.success-toast",
      })

      onClose()
      redirectToMembersApp(response.node.id)
    } catch (error) {
      displayErrorToast(error)
    }
  }

  function redirectToMembersApp(productAppId: string) {
    if (product) {
      history.push(
        generatePath(ROUTE_NAMES.PRODUCT.MEMBER_DIRECTORY, {
          productSlug: product.slug,
          appId: productAppId,
        })
      )
    } else {
      history.push(
        generatePath(ROUTE_NAMES.COMMUNITY.MEMBER_DIRECTORY, {
          appId: productAppId,
        })
      )
    }
  }

  async function handleEditApp() {
    try {
      const { didSave, response } = await editForm.submit(
        {
          id: editForm.state.id,
          visibility: editForm.changedState.visibility,
          visibilityGroups: editForm.state.visibilityGroups,
          visibilityMembers: editForm.state.visibilityMembers,
          appDirectoryItems: editForm.state.appDirectoryItems,
          badge: editForm.state.badge,
          customAppTitle: editForm.state.customAppTitle,
          navSectionId: editForm.changedState.navSectionId,
        },
        {
          connections: [],
          variables: {
            isOrgTopLevel: !createForm.state.productId && !createForm.state.navSectionId,
          },
        }
      )

      if (!didSave || !response?.node) return

      displaySuccessToast({
        message: "Members app updated!",
        testid: "EditMembersAppForm.success-toast",
      })

      onClose()
    } catch (error) {
      displayErrorToast(error)
    }
  }
}

export default observer(MembersSettingsForm)
